import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  errorMessage: String;

  forgotPasswordForm: FormGroup;

  constructor(protected auth: AuthService, private fb: FormBuilder, private router: Router) {
    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
    },);
  }

  ngOnInit(): void {
  }

  get formControls() {
    return this.forgotPasswordForm.controls;
  }

  onSubmit(): void {
    this.errorMessage = "Sending email with reset link...";
    this.auth.sendPasswordResetEmail(this.forgotPasswordForm.controls['email'].value)
      .then(value => {
        console.log("email sent");
        this.router.navigate(["reset-email-sent"])
      })
      .catch(reason => {
        console.log("failed to send reset email: " + reason);
        this.errorMessage = reason;
      });
  }

  inputClicked() {
    if (this.errorMessage) {
      this.errorMessage = "";
    }
  }
}
