import {Address} from "./address";

export class RenterInfo {
  agentId: String
  fullName: String
  phoneNumber: String
  occupation: String
  address: Address
  amenities: string[]
  apartmentsShown: string[]
  timePosted: number;
  renterKey: string;
}
