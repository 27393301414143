import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {AuthService} from "../../../auth/auth.service";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  errorMessage: String;

  private code: string;
  private codeExpired: boolean = true;

  resetPasswordForm: FormGroup;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private auth: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.fb.group({
      email: new FormControl({value: '', disabled: true}, [Validators.required, Validators.email]),
      password: [null, [Validators.required, Validators.minLength(8)]], //Validators.minLength(8)
    },);

    this.route.queryParams.subscribe(params => {
      this.errorMessage = "";
      this.code = params['oobCode'];
      if (this.code) {
        this.auth.verifyPasswordResetCode(this.code)
          .then((email) => {
            if (email) {
              this.codeExpired = false;
              this.resetPasswordForm = this.fb.group({
                email: [{value: email, disabled: true}, []],
                password: ['', [Validators.required, Validators.minLength(8)]]
              })
            } else {
              this.codeExpired = true;
              console.log("code expired")
              this.errorMessage = "code expired. Please reset the password again."
            }
          })
          .catch(reason => {
            this.errorMessage = "Error: " + reason;
          })
      }
    });
  }

  get formControl() {
    return this.resetPasswordForm.controls;
  }

  onSubmit() {
    console.log("on submit called")
    this.auth.confirmPasswordReset(this.code, this.resetPasswordForm.value.password)
      .then((result) => {
        console.log("successful password reset");
        const navigationExtras: NavigationExtras = {state: {data: 'Successfully reset password.'}};
        this.router.navigate(["/login"], navigationExtras);
      })
      .catch(reason => {
        this.errorMessage = "Error: " + reason;
      })
  }


}
