import {Injectable, OnInit} from '@angular/core';

import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';

import {Agent} from 'src/app/model/agent.model';
import {map} from 'rxjs/operators';
import {Listings} from './model/listings';

import {BehaviorSubject, Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Address} from './model/address';
import {Router} from "@angular/router";
import {RenterInfo} from "./model/renter-info";

@Injectable({
  providedIn: 'root'
})
export class ReplayinfoService implements OnInit {

  size$: BehaviorSubject<any>;

  agentId = '';
  user: Observable<any>;

  loggedAgent: Observable<Agent>;

  private agent: Observable<Agent>;

  private agentValue: Agent;

  public listingsRef: AngularFireList<Listings>;
  public addressRef: AngularFireList<Address>;
  public renterRegistrationRef: AngularFireList<RenterInfo>;

  public listings: Observable<Listings[]>;
  public registeredRenters: Observable<RenterInfo[]>;


  allAmenities = Map;
  firebaseDb: AngularFireDatabase;

  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;


  constructor(private fbDB: AngularFireDatabase, public afAuth: AngularFireAuth, private deviceService: DeviceDetectorService, public router: Router) {
    this.firebaseDb = fbDB;
    console.log('get device info: ' + JSON.stringify(this.deviceService.getDeviceInfo()));
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    this.addressRef = this.firebaseDb.list('Addresses/');
    this.renterRegistrationRef = this.firebaseDb.list('RenterRegistrations/');
    const agentIdFromSession = JSON.parse(localStorage.getItem('user')).uid;
    this.listingsRef = this.firebaseDb.list('Listings/', ref => ref.orderByChild('authorKey')
      .equalTo('' + agentIdFromSession));
    // this.agent = this.afAuth.user.pipe(mergeMap(val => this.firebaseDb.object<Agent>('Agents/' + t).valueChanges()));
    this.agent = this.firebaseDb.object<Agent>('Agents/' + agentIdFromSession).valueChanges();
  }


  ngOnInit() {
    this.allAmenities['noFee'] = 'No Fee';
    this.allAmenities['roofDeck'] = 'Roof Deck';
    this.allAmenities['convertible'] = 'Convertible';
    this.allAmenities['parking'] = 'Parking';
    this.allAmenities['elevator'] = 'Elevator';
    this.allAmenities['gym'] = 'Gym';
    this.allAmenities['petFriendly'] = 'Pet Friendly';
    this.allAmenities['dishwasher'] = 'Dishwasher';
    this.allAmenities['laundry'] = 'Laundry';
    this.allAmenities['balcony'] = 'Balcony';
    this.allAmenities['doorman'] = 'Door Man';

    console.log('get device info: ');
  }

  getAgent() {
    const agentIdFromSession = JSON.parse(localStorage.getItem('user')).uid;
    console.log("agent id from session: " + agentIdFromSession);
    return this.firebaseDb.object<Agent>('Agents/' + agentIdFromSession).valueChanges();
  }

  getAgentListings(agentUid) {
    // todo change the agent id

    this.listings = this.listingsRef.snapshotChanges().pipe(
      map(changes => {
        return changes.map(c => {
          return ({key: c.payload.key, ...c.payload.val()});
        });
      }));
    console.log('get agent listings called');
    return this.listings;
  }

  getListingById(id) {
    if (!this.listings) {
      this.listings = this.listingsRef.snapshotChanges().pipe(
        map(changes => {
          return changes.map(c => {
            return ({key: c.payload.key, ...c.payload.val()});
          });
        }));
    }
    return this.listings.pipe(map(items => items.filter(item => item.listingKey === id)));
  }


  getReadableDate(millisecond) {
    const date = new Date(millisecond * 1000);
    const options = {
      year: 'numeric', month: 'numeric', day: 'numeric',
    };

    return date.toLocaleDateString('en');

  }

  getAmenitiesUiNames() {
    return this.allAmenities;
  }

  updateAddressById(key, address: Address) {
    this.addressRef.update(key, address)
      .catch((x) => console.log(x));
  }

  createNewListing(listing: Listings) {
    this.addressRef.set(listing.addressKey, listing.address)
      .then((result) => {
        console.log('address update res ' + result);
      })
      .catch((x) => console.log('failed to update address ' + x));
    this.listingsRef.set(listing.listingKey, listing)
      .then((result) => console.log('listing update res ' + result))
      .then(value => this.router.navigate(['my-listings']))
      .catch((x) => console.log('failed to update listing ' + x));
  }

  insertNewRenter(renterInfo: RenterInfo) {
    return this.renterRegistrationRef.set(renterInfo.renterKey, renterInfo);
  }

  generateNewListingKey() {
    return this.firebaseDb.createPushId();
  }


  getRegisteredRenters() {
    this.registeredRenters = this.renterRegistrationRef.snapshotChanges().pipe(
      map(changes => {
        return changes.map(c => {
          return ({key: c.payload.key, ...c.payload.val()});
        });
      }));
    console.log('get registered renters called');
    return this.registeredRenters;
  }

  getRenterInfo(renterKey: string) {
    if(!this.registeredRenters) {
      this.getRegisteredRenters();
    }
    return this.registeredRenters.pipe(map(items => items.filter(item => item.renterKey === renterKey)));
  }
}

