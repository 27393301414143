import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reset-email-sent',
  templateUrl: './reset-email-sent.component.html',
  styleUrls: ['./reset-email-sent.component.css']
})
export class ResetEmailSentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
