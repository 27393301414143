export class Address {

    city: string;

    country: string;

    county: string;

    full_address: string;

    latitude: number;

    longitude: number;

    neighborhood: string;

    place_id: string;

    postalCode: string;

    route: string;

    state: string;

    street: string;

    street_number: string;

    subNeighborhood: string;

    sub_locality: string;

    unitOrApartmentNumber: string;

}
