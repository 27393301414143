import {Injectable} from '@angular/core';
import {PutObjectCommand, S3Client} from '@aws-sdk/client-s3';
import {Upload} from '@aws-sdk/lib-storage';

@Injectable({
  providedIn: 'root'
})
export class AwsuploadService {

  s3Client: S3Client;

  REGION = 'us-east-1';
  SECRET_ACCESS_KEY = '18Fm/ULTN3iUm55mt66//C3YeqWblTKLn2WyN4t3';
  ACCESS_KEY = 'AKIAYTLS5XYK3OS5PTVJ';

  constructor() {
  }

  async uploadMedia(file: File) {
    const REGION = 'us-east-1';
    const SECRET_ACCESS_KEY = '18Fm/ULTN3iUm55mt66//C3YeqWblTKLn2WyN4t3';
    const ACCESS_KEY = 'AKIAYTLS5XYK3OS5PTVJ';

    const s3Client = new S3Client({
      apiVersion: '23',
      region: REGION,
      credentials: {accessKeyId: ACCESS_KEY, secretAccessKey: SECRET_ACCESS_KEY}, maxAttempts: 1
    });

    // Set the parameters
    const uploadParams = {
      Bucket: 'testbucket1-replaylistings',
      // Add the required 'Key' parameter using the 'path' module.
      Key: 'testphoto',
      // Add the required 'Body' parameter
      Body: file,
      type: file.type,
    };


    try {
      const data = await s3Client.send(new PutObjectCommand(uploadParams));
      console.log('Success', data);
      return data; // For unit tests.
    } catch (err) {
      console.log('Error', err);
    }
  }


  uploadProgress(file: File, type: String, fileName: String) {
    const bucketKey = type === 'image' ? 'image/' + fileName : 'video/' + fileName;

    if (!this.s3Client) {
      this.s3Client = new S3Client({
        apiVersion: '23',
        region: this.REGION,
        credentials: {accessKeyId: this.ACCESS_KEY, secretAccessKey: this.SECRET_ACCESS_KEY}, maxAttempts: 1
      });
    }

    return new Upload({
      params: {
        Bucket: 'replay-web-upload',
        // Add the required 'Key' parameter using the 'path' module.
        Key: bucketKey,
        // Add the required 'Body' parameter
        Body: file.stream(),
      },
      client: this.s3Client,
      queueSize: 3,
    });
  }
}
