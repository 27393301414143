import {Address} from './address';
import {Agent} from './agent.model';

export class Listings {

  address: Address;

  addressKey: string;

  agent: Agent;

  amenities: string[];

  authorKey: string;

  bathroom: string;

  bedroom: string;

  heartCount: number;

  listingKey: string;

  query_company: string;

  rentAmount: number;

  showAddress: boolean;

  thumbnailURL: string;

  timePosted: number;

  videoApproved: number;

  videoURL: string;

  viewCount: number;

  aptType: number;

  description: string;

  key: string;
}
