import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {Listings} from 'src/app/model/listings';
import {ReplayinfoService} from 'src/app/replayinfo.service';
import {ColumnMode, DatatableComponent, SelectionType, SortType} from '@swimlane/ngx-datatable'
import {mergeMap} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';
import {RenterInfo} from "../../model/renter-info";


@Component({
  selector: 'app-renters-registered',
  templateUrl: './renters-registered.component.html',
  styleUrls: ['./renters-registered.component.css']
})
export class RentersRegisteredComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  SortType = SortType;

  // columns = [
  //   {prop: 'address.unitOrApartmentNumber', name: 'Unit'},
  //   {prop: 'address.street', name: 'Address'},
  //   {prop: 'address.subNeighborhood', name: 'Neighborhood'},
  // ];

  public rentersInfo: Observable<RenterInfo[]>;

  selected = [];

  loading: boolean = true;

  constructor(public replayinfoService: ReplayinfoService, public afAuth: AngularFireAuth) {

  }

  rows: RenterInfo[];
  temp: RenterInfo[];

  ngOnInit() {

    this.rentersInfo = this.replayinfoService.getRegisteredRenters();
    this.rentersInfo.subscribe(x => {
      console.log(x)
      this.temp = x;
      this.rows = x;
      this.loading = false;
    });
  }


  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data (-1) not found
    const temp = this.temp.filter(function (d) {
      return (d.agentId + "").toLowerCase().indexOf(val) !== -1
        || (d.fullName + "").toLowerCase().indexOf(val) !== -1
        || d.address.street.toLowerCase().indexOf(val) !== -1 || !val
        || d.apartmentsShown.includes(val)
        || d.agentId.toLowerCase().indexOf(val) !== -1;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  onSelect({selected}) {
    console.log('Select Event', selected, this.selected);
  }

  onActivate(event) {
    console.log('Activate Event', event);
  }

  getReadableDate(date) {
    return this.replayinfoService.getReadableDate(date);
  }

}
