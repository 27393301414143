import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { RegisterComponent } from './pages/register/register.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule, } from '@angular/fire/database';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';


import { environment } from '../environments/environment';
import { ListingComponent } from './pages/listing/listing.component';
import { CustomDatePipe } from './rlpipes/custom-date.pipe';

import { ClipboardModule } from 'ngx-clipboard';
import { NewlistingComponent } from './pages/newListing/newlisting/newlisting.component';
import { RegisterMxComponent } from './pages/register-mx/register-mx.component';
import { ForgotPasswordComponent } from './pages/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/auth/reset-password/reset-password.component';
import { ResetEmailSentComponent } from './pages/auth/reset-email-sent/reset-email-sent.component';
import { RegisterRenterComponent } from './pages/register-renter/register-renter.component';
import { RentersRegisteredComponent } from './pages/renters-registered/renters-registered.component';


@NgModule({
  imports: [
    NgxDatatableModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    ClipboardModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ListingComponent,
    CustomDatePipe,
    NewlistingComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResetEmailSentComponent,
    RegisterRenterComponent,
    RentersRegisteredComponent,
    //RegisterMxComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
