import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(value: any){
    var a =  "asdfasd";
    console.log("******* "+ a)
    return a;
  }

}
