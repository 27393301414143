
import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import { Observable } from 'rxjs';
import { Agent } from 'src/app/model/agent.model';
import { ReplayinfoService } from 'src/app/replayinfo.service';
import { ActivatedRoute } from '@angular/router';
import { Listings } from 'src/app/model/listings';
import { NgForm, FormGroup, FormControl, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {

  aptForm: FormGroup;

  public listings: Observable<Listings[]>;
  listingId: string;
  fb: FormBuilder;

  @ViewChild("search")
  public addresstext: ElementRef;

  @ViewChild("apt")
  public apt: ElementRef;

  @ViewChild("neighborhood")
  public neighborhood: ElementRef;

  @ViewChild("city")
  public city: ElementRef;

  @ViewChild("state")
  public state: ElementRef;

  @ViewChild("country")
  public country: ElementRef;

  @ViewChild("postCode")
  public postCode: ElementRef;

  @ViewChild("hideAddress")
  public hideAddress: ElementRef;


  @ViewChild("price")
  public price: ElementRef;

  @ViewChild("bed")
  public bed: ElementRef;

  @ViewChild("bath")
  public bath: ElementRef;

  public aptTypes = [
    { id: 0, name: "Select" },
    { id: 1, name: "Rentals" },
    { id: 2, name: "Sales" }];


  address: String;

  autocompleteInput: string;


  @ViewChild("noFee")
  public noFee: ElementRef;

  @ViewChild("roofDeck")
  public roofDeck: ElementRef;

  @ViewChild("convertible")
  public convertible: ElementRef;

  @ViewChild("parking")
  public parking: ElementRef;

  @ViewChild("elevator")
  public elevator: ElementRef;

  @ViewChild("gym")
  public gym: ElementRef;

  @ViewChild("petFriendly")
  public petFriendly: ElementRef;

  @ViewChild("dishwasher")
  public dishwasher: ElementRef;

  @ViewChild("laundry")
  public laundry: ElementRef;

  @ViewChild("balcony")
  public balcony: ElementRef;

  @ViewChild("doorman")
  public doorman: ElementRef;


  amenitiesRef = new Map();

  constructor(private rlInfoService: ReplayinfoService, private route: ActivatedRoute, private elRef: ElementRef, fb: FormBuilder) {
    this.listingId = this.route.snapshot.params['id'];
    this.fb = fb;
  }

  ngOnInit(): void {
    this.listings = this.rlInfoService.getListingById(this.listingId);
    this.listings.subscribe(x => this.updateUiWithListingDetails(x));
    this.aptForm = this.fb.group({
      aptTypeFGC: [0]
    });
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
    this.amenitiesRef.set("noFee", this.noFee);
    this.amenitiesRef.set("roofDeck", this.roofDeck);
    this.amenitiesRef.set("convertible", this.convertible);
    this.amenitiesRef.set("parking", this.parking);
    this.amenitiesRef.set("elevator", this.elevator);
    this.amenitiesRef.set("gym", this.gym);
    this.amenitiesRef.set("petFriendly", this.petFriendly);
    this.amenitiesRef.set("dishwasher", this.dishwasher);
    this.amenitiesRef.set("laundry", this.laundry);
    this.amenitiesRef.set("balcony", this.balcony);
    this.amenitiesRef.set("doorman", this.doorman);
  }


  getPlaceAutocomplete() {
    console.log("enable auto complete")
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'US' },
        fields: ["address_components"],
        types: ["address"]
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      window["place"] = place;

      let addressComplete = "";

      console.log(place)
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number": {
            addressComplete = component.long_name;
            break;
          }

          case "route": {
            addressComplete += component.short_name;
            this.address = addressComplete;
            break;
          }

          case "postal_code": {
            this.postCode.nativeElement.value = component.long_name;
            break;
          }

          case "locality": {
            this.city.nativeElement.value = component.long_name;
            break;
          }

          case "administrative_area_level_1": {
            this.state.nativeElement.value = component.short_name;
            break;
          }
          case "country":
            this.country.nativeElement.value = component.long_name;
            break;
        }
      }
      this.apt.nativeElement.focus();
    });
  }


  updateUiWithListingDetails(listingInfos: Listings[]) {
    var lst = listingInfos[0];
    console.log(lst);

    var addressToShow = "";

    if (lst.showAddress) {
      addressToShow = lst.address.street;
    } else {
      var tokenizedAddress = lst.address.street.split(" ");
      addressToShow = (tokenizedAddress.slice(1)).join(" ");
    }
    this.address = lst.address.unitOrApartmentNumber + " " + addressToShow

    this.apt.nativeElement.value = lst.address.unitOrApartmentNumber;
    this.neighborhood.nativeElement.value = lst.address.subNeighborhood;
    this.city.nativeElement.value = lst.address.city;
    this.state.nativeElement.value = lst.address.state;
    this.country.nativeElement.value = lst.address.country;
    this.postCode.nativeElement.value = lst.address.postalCode;
    this.hideAddress.nativeElement.checked = !lst.showAddress;

    this.price.nativeElement.value = lst.rentAmount;
    this.bed.nativeElement.value = lst.bedroom;
    this.bath.nativeElement.value = lst.bathroom;

    this.aptForm.get("aptTypeFGC").patchValue(1);


    this.updateAmenities(lst.amenities);

  }

  updateAmenities(amenities) {
    amenities.forEach(element => {
      this.amenitiesRef.get(element).nativeElement.checked = true;
    });
  }

  updateListing() {

  }

}
