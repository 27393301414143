import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {Listings} from 'src/app/model/listings';
import {FormGroup, NgForm} from '@angular/forms';
import {Address} from '../../../model/address';
import {ReplayinfoService} from '../../../replayinfo.service';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {Agent} from '../../../model/agent.model';
import {AwsuploadService} from '../../../service/awsupload.service';
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-newlisting',
  templateUrl: './newlisting.component.html',
  styleUrls: ['./newlisting.component.css']
})
export class NewlistingComponent implements AfterViewInit {

  @ViewChild('listingForm') listingForm: NgForm;
  public aptTypes = [
    {id: 1, name: 'Rentals'},
    {id: 2, name: 'Sales'}];

  amenitiesRef = new Map();
  addressNew = new Address();
  listing = new Listings();

  @ViewChild('search')
  public addressText: ElementRef;

  agentInfo: Observable<Agent>;
  public listings: Observable<Listings[]>;
  listingId: string;
  previewData: any [] = [];
  listingMap = new Map();
  awsUpload: AwsuploadService;
  imageProgress = 0;
  imageMax: number;
  videoProgress = 0;
  videoMax: number;
  isNewListing = true;

  constructor(private rlInfoService: ReplayinfoService, private route: ActivatedRoute, awsUploadService: AwsuploadService) {
    this.listing.address = this.addressNew;
    this.awsUpload = awsUploadService;
    this.amenitiesRef.set('noFee', 'No Fee');
    this.amenitiesRef.set('roofDeck', 'Roof Deck');
    this.amenitiesRef.set('convertible', 'Convertible');
    this.amenitiesRef.set('parking', 'Parking');
    this.amenitiesRef.set('elevator', 'Elevator');
    this.amenitiesRef.set('gym', 'Gym');
    this.amenitiesRef.set('petFriendly', 'Pet Friendly');
    this.amenitiesRef.set('dishwasher', 'Dishwasher');
    this.amenitiesRef.set('laundry', 'Laundry');
    this.amenitiesRef.set('balcony', 'Balcony');
    this.amenitiesRef.set('doorman', 'Doorman');

    this.listingId = this.route.snapshot.params['id'];
    console.log('listing id from route ' + this.listingId);
    if (this.listingId && this.listingId.startsWith('-')) {
      this.isNewListing = false;
    }

    this.agentInfo = this.rlInfoService.getAgent();

    if (this.isNewListing) {
      console.log('is new listing');
      this.onNewListing();
    } else {
      this.listings = this.rlInfoService.getListingById(this.listingId);
      this.listings.subscribe(x => {
        this.addressNew = x[0].address;
        this.listing = x[0];
        console.log('OO' + JSON.stringify(this.listing));

        setTimeout(() => {
          this.addressText.nativeElement.value = this.addressNew.street_number + ' ' + this.addressNew.street;
          this.listing.aptType = 1;
          this.listingForm.setValue(this.listing);
        });
      });
    }
  }

  onNewListing() {
    console.log("On new listing called")
    this.listing = new Listings();
    this.listing.amenities = [];
    this.listing.addressKey = this.rlInfoService.generateNewListingKey();
    this.addressNew = new Address();
    this.listing.address = this.addressNew;
    this.agentInfo.subscribe(x => {
      console.log(' response: ' + JSON.stringify(x));
      this.listing.agent = x;
      this.listing.query_company = x.company;
      this.listing.authorKey = x.uid;
      console.log("new listing details: " + JSON.stringify(this.listing))
    });

    this.listing.heartCount = 0;
    this.listing.aptType = 1;
    this.listing.showAddress = true;
    this.listing.videoApproved = 0;
    this.listing.timePosted = Date.now() / 1000;
    this.listing.viewCount = 0;
    this.listing.listingKey = this.rlInfoService.generateNewListingKey();
    this.listing.description = '';
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
    console.log("test")
    // this.updateAmenities(this.listing.amenities);
  }

  updateAmenities(amenities) {
    amenities.forEach(element => {
      this.amenitiesRef.get(element).nativeElement.checked = true;
    });
  }

  onSubmit() {
    console.log(this.listing);
    this.rlInfoService.createNewListing(this.listing);
  }

  setDefaults() {
    // this.listingForm.setValue(this.listing);
  }

  changeAddress() {
    // this.listingForm.controls['addressasdfa'].setValue('1');
  }

  onNativeChange(e) {
    const valueOfCheckbox = this.amenitiesRef.get(e.target.id);
    if (e.target.checked) {
      this.listing.amenities.push(e.target.id);
      console.log(JSON.stringify(this.listing));
    } else {
      this.listing.amenities = this.listing.amenities.filter(function (value, index, arr) {
        return value !== e.target.id;
      });
      console.log('Not checked ' + JSON.stringify(this.listing));
    }
  }

  getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        componentRestrictions: {country: 'US'},
        fields: ['address_components', 'formatted_address', 'geometry', 'place_id'],
        types: ['address']
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
        console.log('Place changed google');
        // reset the apt and neighborhood
        this.listing.address.unitOrApartmentNumber = '';
        this.listing.address.subNeighborhood = '';

        const place = autocomplete.getPlace();
        // window['place'] = place;


        console.log('place' + place);
        this.addressNew.full_address = place['formatted_address'];
        this.addressNew.latitude = place.geometry.location.lat();
        this.addressNew.longitude = place.geometry.location.lng();
        this.addressNew.place_id = place.place_id;


        for (const component of place.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case 'locality': {
              this.addressNew.city = component.long_name;
              // this.city.nativeElement.value = component.long_name;
              break;
            }
            case 'country': {
              this.addressNew.country = component.long_name;
              // this.country.nativeElement.value = component.long_name;
              break;
            }

            case 'administrative_area_level_2': {
              this.addressNew.county = component.short_name;
              // this.state.nativeElement.value = component.short_name;
              break;
            }

            case 'sublocality_level_1': {
              this.addressNew.neighborhood = component.short_name;
              this.addressNew.sub_locality = component.short_name;
              // this.state.nativeElement.value = component.short_name;
              break;
            }
            case 'postal_code': {
              this.addressNew.postalCode = component.long_name;
              // this.postCode.nativeElement.value = component.long_name;
              break;
            }

            case 'route': {
              this.addressNew.route = component.short_name;
              // this.state.nativeElement.value = component.short_name;
              break;
            }

            case 'administrative_area_level_1': {
              this.addressNew.state = component.short_name;
              // this.state.nativeElement.value = component.short_name;
              break;
            }

            case 'street_number': {
              this.addressNew.street_number = component.short_name;
              break;
            }


            case 'administrative_area_level_1': {
              this.addressNew.street = this.addressNew.route;
              // this.state.nativeElement.value = component.short_name;
              break;
            }

          }
        }
        this.addressNew.street = this.addressNew.route;
        console.log(' asdfasd' + JSON.stringify(this.addressNew));
        // this.apt.nativeElement.focus();
        const address1 = this.listingForm.controls['address'] as FormGroup;
        address1.patchValue(this.addressNew);
        this.addressText.nativeElement.value = this.addressNew.street_number + ' ' + this.addressNew.street;
      }
    );
  }

  showFormControls(form: any) {
    return form && form.controls.name && form.controls.name.value;
  }

  previewFile(file, type) {

    const reader = new FileReader();
    const mediaObjectsMap = this.listingMap;
    const list = this.listing;
    const obj = new FormData().append('file', file);
    const fileExtension = '.' + file.name.split('.').pop();
    const uuidString = uuidv4();

    const fileName = uuidString + fileExtension;

    console.log('file size: ' + file.size);
    console.log('file type: ' + type);
    console.log('file extension: ' + fileExtension);
    console.log('uuid of the file: ' + uuidString);
    console.log('fileName: ' + fileName);


    if (type === 'image') {
      this.imageMax = file.size;
    } else {
      this.videoMax = file.size;
    }

    const upload = this.awsUpload.uploadProgress(file, type, fileName);

    upload.on('httpUploadProgress', (progress) => {

      if (type === 'image') {
        this.imageProgress = 100 * Math.round((progress.loaded / this.imageMax));
      } else {
        this.videoProgress = 100 * Math.round((progress.loaded / this.videoMax));
      }
      console.log(progress);
    });

    upload.done()
      .catch((ex) => console.log('Error', ex));

    reader.onload = function (data) {
      const src = data.target.result;

      const size = ((file.size / (1024 * 1024)) > 1) ? (file.size / (1024 * 1024)) + ' mB' : (file.size / 1024) + ' kB';

      const objectMedia = {
        'name': file.name, 'size': size, 'type': file.type,
        'src': src, 'data': obj
      };


      if (file.type.startsWith('image')) {
        mediaObjectsMap.set('image', objectMedia);
        list.thumbnailURL = 'https://replay-web-upload.s3.amazonaws.com/image/' + fileName;
      } else if (file.type.startsWith('video')) {
        mediaObjectsMap.set('video', objectMedia);
        list.videoURL = 'https://replay-web-upload.s3.amazonaws.com/video/' + fileName;
      }

      console.log(mediaObjectsMap);
      // _arr.push({
      //   'name': file.name, 'size': size, 'type': file.type,
      //   'src': src, 'data': obj
      // });
    };
    const urlResource = reader.readAsDataURL(file);
    console.log('resource url: ' + urlResource);
  }

  uploadFile(e, type) {
    e.preventDefault();
    let files = FileList;
    if (type === 'formControl') {
      files = e.target.files;
    } else if (type === 'drop') {
      files = e.originalEvent.dataTransfer.files;
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.type.indexOf('image') !== -1) {
        this.previewFile(file, 'image');
      } else if (file.type.indexOf('video') !== -1) {
        this.previewFile(file, 'video');
      } else {
        alert(file.name + ' is not supported');
      }
    }
  }

  upload(obj) {

    console.log('upload clicked');
  }

  remove(data) {
    if (data === 'image') {
      this.listing.thumbnailURL = null;
    } else if (data === 'video') {
      this.listing.videoURL = null;
    }

    console.log('asdfasdfadsf: ' + data);

    this.listingMap.delete(data);
    const index = this.previewData.indexOf(data);
    this.previewData.splice(index, 1);
  }

  dropHandler(ev, type) {
    console.log('File(s) dropped' + type);

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          console.log('... file[' + i + '].name = ' + file.name);
          this.previewFile(file, type);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
      }
    }
  }

  dropImageHandler(ev, type) {
    console.log('File(s) dropped' + JSON.stringify(ev) + ' ' + type);

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          console.log('... file[' + i + '].name = ' + file.name);
          this.previewFile(file, type);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < ev.dataTransfer.files.length; i++) {
        console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
      }
    }
  }

  dragOverHandler(ev) {
    console.log('File(s) in drop zone');

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
  }

  onFileChange(event, type) {
    if (event.target.files && event.target.files[0]) {
      const fileCount = event.target.files.length;
      for (let i = 0; i < fileCount; i++) {

        // todo add type here
        this.previewFile(event.target.files[i], type);
      }
    }
  }

  isVideoAndThumbAdded() {
    const validVideoImage = this.listing.videoURL && this.listing.thumbnailURL;
    console.log('is video and image added: ' + validVideoImage);
    return validVideoImage;
  }

  shouldDisableSubmit() {

    if (this.isNewListing) {

      if (this.listingForm.valid && this.listing.videoURL && this.listing.thumbnailURL) {
        return false;
      }

    }
    return true;

  }


}

