import {Component, ElementRef, OnInit} from '@angular/core';
import {ROUTES} from '../sidebar/sidebar.component';
import {Location} from '@angular/common';
import {Router} from '@angular/router';


import {Observable} from 'rxjs';
import {Agent} from 'src/app/model/agent.model';

import {AngularFireDatabase} from '@angular/fire/database';
import {ReplayinfoService} from 'src/app/replayinfo.service';
import {AuthService} from 'src/app/auth/auth.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public focus;
  public listTitles: any[];
  public location: Location;

  agentInfo: Observable<Agent>;

  constructor(location: Location, public element: ElementRef, public router: Router,
              public db: AngularFireDatabase, public rlService: ReplayinfoService, public authService: AuthService) {
    this.location = location;
    // this.items = this.db.list<Agent>('Agents').valueChanges();
    // this.db.list<Agent>('Agents').snapshotChanges().subscribe(x => x.forEach(y => console.log(y.payload.val())))
    // rlService.getAgent().subscribe(x => console.log(JSON.stringify(x)));
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.agentInfo = this.rlService.getAgent();
    // this.agentInfo.subscribe(x => console.log(JSON.stringify(x) + 'user details'));
  }

  ngOnInit() {

  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }


  displayAgentsId() {
  }

}
