// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // firebase: {
  //   apiKey: "AIzaSyCeS2FgeRKu2FHOVx2yMNSXLR_JItFvyQI",
  //   authDomain: "replaylistingstestdatabase.firebaseapp.com",
  //   databaseURL: "https://replaylistingstestdatabase.firebaseio.com",
  //   projectId: "replaylistingstestdatabase",
  //   storageBucket: "replaylistingstestdatabase.appspot.com",
  //   messagingSenderId: "113328173423",
  //   appId: "1:113328173423:web:b19d351da43e8023af789a"
  // }
  firebase : {
    apiKey: "AIzaSyADxI_AjMDEIO_-WEeWewY-aNm9VEj8wP8",
    authDomain: "replaylistings-95421.firebaseapp.com",
    databaseURL: "https://replaylistings-95421.firebaseio.com",
    projectId: "replaylistings-95421",
    storageBucket: "replaylistings-95421.appspot.com",
    messagingSenderId: "422191381181",
    appId: "1:422191381181:web:6177f1cf6184631eb1d5d4",
    measurementId: "G-5TEV27ZHEF"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
