import {AfterViewInit, Component, ElementRef, ViewChild} from '@angular/core';
import {FormGroup, NgForm} from "@angular/forms";
import {RenterInfo} from "../../model/renter-info";
import {Address} from "../../model/address";
import {ReplayinfoService} from "../../replayinfo.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-register-renter',
  templateUrl: './register-renter.component.html',
  styleUrls: ['./register-renter.component.css']
})
export class RegisterRenterComponent implements AfterViewInit {

  @ViewChild('renterRegistrationForm') renterRegistrationForm: NgForm;

  amenitiesRef = new Map();

  @ViewChild('search')
  public addressText: ElementRef;
  addressNew = new Address();
  renterInfo = new RenterInfo();

  apartmentShownArray = []

  isNewRenter: boolean = true;
  renterKey: string;

  constructor(private replayInfoService: ReplayinfoService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.renterInfo.address = this.addressNew;

    this.amenitiesRef.set('noFee', 'No Fee');
    this.amenitiesRef.set('roofDeck', 'Roof Deck');
    this.amenitiesRef.set('convertible', 'Convertible');
    this.amenitiesRef.set('parking', 'Parking');
    this.amenitiesRef.set('elevator', 'Elevator');
    this.amenitiesRef.set('gym', 'Gym');
    this.amenitiesRef.set('petFriendly', 'Pet Friendly');
    this.amenitiesRef.set('dishwasher', 'Dishwasher');
    this.amenitiesRef.set('laundry', 'Laundry');
    this.amenitiesRef.set('balcony', 'Balcony');
    this.amenitiesRef.set('doorman', 'Doorman');
    console.log("Initialized")

    this.renterKey = this.activatedRoute.snapshot.params['id'];
    console.log('renter id from route ' + this.renterKey);
    if (this.renterKey && this.renterKey.startsWith('-')) {
      this.isNewRenter = false;
    }
    if (this.isNewRenter) {
      this.renterInfo.address = this.addressNew;

      this.renterInfo.apartmentsShown = [];
      this.renterInfo.amenities = [];
      this.renterInfo.timePosted = Date.now() / 1000;
      this.renterInfo.renterKey = this.replayInfoService.generateNewListingKey();
      this.apartmentShownArray.push("")
    } else {
      this.replayInfoService.getRenterInfo(this.renterKey)
        .subscribe(value => {
          this.renterInfo = value[0];
          this.addressNew = this.renterInfo.address;
          this.renterInfo.apartmentsShown.forEach(value1 => this.apartmentShownArray.push(value1))
          console.log('renter ' + JSON.stringify(this.renterInfo));

          setTimeout(() => {
            this.addressText.nativeElement.value = this.renterInfo.address.street_number + ' ' + this.renterInfo.address.street;
          });
        })

    }


  }


  addApartmentShown() {
    this.apartmentShownArray.push("");
  }


  onSubmit() {
    console.log(JSON.stringify(this.renterInfo))
    // this.renterInfo.agentId = this.replayInfoService.generateNewListingKey();
    this.replayInfoService.insertNewRenter(this.renterInfo).then(value => {
      this.router.navigate(["/registered-renters"])
    })
  }

  ngAfterViewInit() {
    console.log("test")
    this.getPlaceAutocomplete();
    // this.updateAmenities(this.listing.amenities);
    // console.log('amanities ' + JSON.stringify(this.amenitiesRef));

  }

  onNativeChange(e) {
    const valueOfCheckbox = this.amenitiesRef.get(e.target.id);
    // console.log("value of checked box: " + valueOfCheckbox);
    // console.log("target id: " + e.target.id);
    if (e.target.checked) {
      this.renterInfo.amenities.push(e.target.id);
      // console.log(JSON.stringify(this.renterInfo));
    } else {
      this.renterInfo.amenities = this.renterInfo.amenities.filter(function (value, index, arr) {
        return value !== e.target.id;
      });
      // console.log('Not checked ' + JSON.stringify(this.renterInfo));
    }
  }

  getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addressText.nativeElement,
      {
        componentRestrictions: {country: 'US'},
        fields: ['address_components', 'formatted_address', 'geometry', 'place_id'],
        types: ['address']
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
        // console.log('Place changed google');
        // reset the apt and neighborhood
        this.renterInfo.address.unitOrApartmentNumber = '';
        this.renterInfo.address.subNeighborhood = '';

        const place = autocomplete.getPlace();
        // window['place'] = place;


        // console.log('place' + place);
        this.addressNew.full_address = place['formatted_address'];
        this.addressNew.latitude = place.geometry.location.lat();
        this.addressNew.longitude = place.geometry.location.lng();
        this.addressNew.place_id = place.place_id;


        for (const component of place.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case 'locality': {
              this.addressNew.city = component.long_name;
              // this.city.nativeElement.value = component.long_name;
              break;
            }
            case 'country': {
              this.addressNew.country = component.long_name;
              // this.country.nativeElement.value = component.long_name;
              break;
            }

            case 'administrative_area_level_2': {
              this.addressNew.county = component.short_name;
              // this.state.nativeElement.value = component.short_name;
              break;
            }

            case 'sublocality_level_1': {
              this.addressNew.neighborhood = component.short_name;
              this.addressNew.sub_locality = component.short_name;
              // this.state.nativeElement.value = component.short_name;
              break;
            }
            case 'postal_code': {
              this.addressNew.postalCode = component.long_name;
              // this.postCode.nativeElement.value = component.long_name;
              break;
            }

            case 'route': {
              this.addressNew.route = component.short_name;
              // this.state.nativeElement.value = component.short_name;
              break;
            }

            case 'administrative_area_level_1': {
              this.addressNew.state = component.short_name;
              // this.state.nativeElement.value = component.short_name;
              break;
            }

            case 'street_number': {
              this.addressNew.street_number = component.short_name;
              break;
            }


            case 'administrative_area_level_1': {
              this.addressNew.street = this.addressNew.route;
              // this.state.nativeElement.value = component.short_name;
              break;
            }

          }
        }
        this.addressNew.street = this.addressNew.route;
        // this.apt.nativeElement.focus();
        const address1 = this.renterRegistrationForm.controls['address'] as FormGroup;
        address1.patchValue(this.addressNew);
        this.addressText.nativeElement.value = this.addressNew.street_number + ' ' + this.addressNew.street;
      }
    );
  }
}
