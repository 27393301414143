import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from 'src/app/auth/auth.service';
import {Agent} from 'src/app/model/agent.model';
import {ReplayinfoService} from 'src/app/replayinfo.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  // {path: '/dashboard', title: 'Dashboard', icon: 'ni-book-bookmark text-primary', class: ''},
  // {path: '/maps', title: 'Maps', icon: 'ni-pin-3 text-orange', class: ''},
  {path: '/user-profile', title: 'My profile', icon: 'ni-circle-08 text-yellow', class: ''},
  {path: '/listings', title: 'Create Listing', icon: 'ni-fat-add text-red', class: ''},
  {path: '/my-listings', title: 'My listings', icon: 'ni-building text-green', class: ''},
  {path: '/register-renter', title: 'Register Renter', icon: 'ni-fat-add text-red', class: ''},
  {path: '/registered-renters', title: 'Registered Renters', icon: 'ni-single-02 text-green', class: ''},
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  agentInfo: Observable<Agent>;
  _authService: AuthService;
  userUid: string;

  constructor(private router: Router, private rlService: ReplayinfoService, private authService: AuthService) {
    this.agentInfo = rlService.getAgent();
    this._authService = authService;
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.agentInfo.subscribe(event => {
      console.log(' sidebar agent info: '+ event);
      this.userUid = event.uid;
    });
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  ngOnInit() {

  }

}
