import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {User} from '../model/user';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  user: User;

  constructor(public afAuth: AngularFireAuth, public router: Router) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        localStorage.setItem('user', null);
      }
    });
  }

  async logout() {
    await this.afAuth.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['login']);
  }

  signUp(email: string, password: string) {
    console.log("email " + email + " password " + password)
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  sendPasswordResetEmail(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  verifyPasswordResetCode(code: string){
    return this.afAuth.verifyPasswordResetCode(code);
  }
  confirmPasswordReset(code: string, newPassword: string) {
    return this.afAuth.confirmPasswordReset(code, newPassword);
  }


}
